export const ORDER_STATUSES_POLICY: any = {
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  EGY: {
    title: 'SHIPPING_INFO.EGY.ORDER_STATUSES.TITLE',
    content: [
      {
        subtitle: 'SHIPPING_INFO.EGY.ORDER_STATUSES.ORDER_RECEIVED',
        text: `SHIPPING_INFO.EGY.ORDER_STATUSES.ORDER_RECEIVED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.EGY.ORDER_STATUSES.SUSPENDED',
        text: `SHIPPING_INFO.EGY.ORDER_STATUSES.SUSPENDED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.EGY.ORDER_STATUSES.CONFIRMED',
        text: `SHIPPING_INFO.EGY.ORDER_STATUSES.CONFIRMED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.EGY.ORDER_STATUSES.PENDING_SHIPPING_COMPANY',
        text: 'SHIPPING_INFO.EGY.ORDER_STATUSES.PENDING_SHIPPING_COMPANY_INFO',
      },
      {
        subtitle: 'SHIPPING_INFO.EGY.ORDER_STATUSES.DELIVERIES_IN_PROGRESS',
        text: `SHIPPING_INFO.EGY.ORDER_STATUSES.DELIVERIES_IN_PROGRESS_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.EGY.ORDER_STATUSES.DELIVERY_SUSPENDED',
        text: `SHIPPING_INFO.EGY.ORDER_STATUSES.DELIVERY_SUSPENDED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.EGY.ORDER_STATUSES.REJECTED_OR_CANCELLED',
        text: `SHIPPING_INFO.EGY.ORDER_STATUSES.REJECTED_OR_CANCELLED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.EGY.ORDER_STATUSES.DELIVERED',
        text: `SHIPPING_INFO.EGY.ORDER_STATUSES.DELIVERED_INFO`,
      },
    ],
  },
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  SAU: {
    title: 'SHIPPING_INFO.SAU.ORDER_STATUSES.TITLE',
    content: [
      {
        subtitle: 'SHIPPING_INFO.SAU.ORDER_STATUSES.ORDER_RECEIVED',
        text: `SHIPPING_INFO.SAU.ORDER_STATUSES.ORDER_RECEIVED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.SAU.ORDER_STATUSES.SUSPENDED',
        text: `SHIPPING_INFO.SAU.ORDER_STATUSES.SUSPENDED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.SAU.ORDER_STATUSES.CONFIRMED',
        text: `SHIPPING_INFO.SAU.ORDER_STATUSES.CONFIRMED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.SAU.ORDER_STATUSES.SHIPPING_TIMINGS',
        text: 'SHIPPING_INFO.SAU.ORDER_STATUSES.SHIPPING_TIMINGS_INFO',
      },
      {
        subtitle: 'SHIPPING_INFO.SAU.ORDER_STATUSES.PENDING_SHIPPING_COMPANY',
        text: 'SHIPPING_INFO.SAU.ORDER_STATUSES.PENDING_SHIPPING_COMPANY_INFO',
      },
      {
        subtitle: 'SHIPPING_INFO.SAU.ORDER_STATUSES.DELIVERIES_IN_PROGRESS',
        text: `SHIPPING_INFO.SAU.ORDER_STATUSES.DELIVERIES_IN_PROGRESS_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.SAU.ORDER_STATUSES.DELIVERY_SUSPENDED',
        text: `SHIPPING_INFO.SAU.ORDER_STATUSES.DELIVERY_SUSPENDED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.SAU.ORDER_STATUSES.REJECTED_OR_CANCELLED',
        text: `SHIPPING_INFO.SAU.ORDER_STATUSES.REJECTED_OR_CANCELLED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.SAU.ORDER_STATUSES.DELIVERED',
        text: `SHIPPING_INFO.SAU.ORDER_STATUSES.DELIVERED_INFO`,
      },
    ],
  },
  ARE: {
    title: 'SHIPPING_INFO.ARE.ORDER_STATUSES.TITLE',
    content: [
      {
        subtitle: 'SHIPPING_INFO.ARE.ORDER_STATUSES.ORDER_RECEIVED',
        text: `SHIPPING_INFO.ARE.ORDER_STATUSES.ORDER_RECEIVED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.ARE.ORDER_STATUSES.SUSPENDED',
        text: `SHIPPING_INFO.ARE.ORDER_STATUSES.SUSPENDED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.ARE.ORDER_STATUSES.CONFIRMED',
        text: `SHIPPING_INFO.ARE.ORDER_STATUSES.CONFIRMED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.ARE.ORDER_STATUSES.SHIPPING_TIMINGS',
        text: 'SHIPPING_INFO.ARE.ORDER_STATUSES.SHIPPING_TIMINGS_INFO',
      },
      {
        subtitle: 'SHIPPING_INFO.ARE.ORDER_STATUSES.PENDING_SHIPPING_COMPANY',
        text: 'SHIPPING_INFO.ARE.ORDER_STATUSES.PENDING_SHIPPING_COMPANY_INFO',
      },
      {
        subtitle: 'SHIPPING_INFO.ARE.ORDER_STATUSES.DELIVERIES_IN_PROGRESS',
        text: `SHIPPING_INFO.ARE.ORDER_STATUSES.DELIVERIES_IN_PROGRESS_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.ARE.ORDER_STATUSES.DELIVERY_SUSPENDED',
        text: `SHIPPING_INFO.ARE.ORDER_STATUSES.DELIVERY_SUSPENDED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.ARE.ORDER_STATUSES.REJECTED_OR_CANCELLED',
        text: `SHIPPING_INFO.ARE.ORDER_STATUSES.REJECTED_OR_CANCELLED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.ARE.ORDER_STATUSES.DELIVERED',
        text: `SHIPPING_INFO.ARE.ORDER_STATUSES.DELIVERED_INFO`,
      },
    ],
  },
  IRQ: {
    title: 'SHIPPING_INFO.IRQ.ORDER_STATUSES.TITLE',
    content: [
      {
        subtitle: 'SHIPPING_INFO.IRQ.ORDER_STATUSES.ORDER_RECEIVED',
        text: `SHIPPING_INFO.IRQ.ORDER_STATUSES.ORDER_RECEIVED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.IRQ.ORDER_STATUSES.SUSPENDED',
        text: `SHIPPING_INFO.IRQ.ORDER_STATUSES.SUSPENDED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.IRQ.ORDER_STATUSES.CONFIRMED',
        text: `SHIPPING_INFO.IRQ.ORDER_STATUSES.CONFIRMED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.IRQ.ORDER_STATUSES.PENDING_SHIPPING_COMPANY',
        text: 'SHIPPING_INFO.IRQ.ORDER_STATUSES.PENDING_SHIPPING_COMPANY_INFO',
      },
      {
        subtitle: 'SHIPPING_INFO.IRQ.ORDER_STATUSES.DELIVERIES_IN_PROGRESS',
        text: `SHIPPING_INFO.IRQ.ORDER_STATUSES.DELIVERIES_IN_PROGRESS_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.IRQ.ORDER_STATUSES.DELIVERY_SUSPENDED',
        text: `SHIPPING_INFO.IRQ.ORDER_STATUSES.DELIVERY_SUSPENDED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.IRQ.ORDER_STATUSES.REJECTED_OR_CANCELLED',
        text: `SHIPPING_INFO.IRQ.ORDER_STATUSES.REJECTED_OR_CANCELLED_INFO`,
      },
      {
        subtitle: 'SHIPPING_INFO.IRQ.ORDER_STATUSES.DELIVERED',
        text: `SHIPPING_INFO.IRQ.ORDER_STATUSES.DELIVERED_INFO`,
      },
    ],
  },
};

export const SHIPPING_COST_POLICY: any = {
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  EGY: {
    title: 'SHIPPING_INFO.EGY.SHIPPING_COST.TITLE',
    image: 'new-shipping-cost.png',
  },
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  SAU: {
    title: 'SHIPPING_INFO.SAU.SHIPPING_COST.TITLE',
    text: 'SHIPPING_INFO.SAU.SHIPPING_COST.INFO',
  },
  ARE: {
    title: 'SHIPPING_INFO.ARE.SHIPPING_COST.TITLE',
    text: 'SHIPPING_INFO.ARE.SHIPPING_COST.INFO',
  },
  IRQ: {
    title: 'SHIPPING_INFO.IRQ.SHIPPING_COST.TITLE',
    text: 'SHIPPING_INFO.IRQ.SHIPPING_COST.INFO',
  },
};

export const REFUNDS_AND_RETURNS_POLICY: any = {
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  EGY: {
    title: 'SHIPPING_INFO.EGY.RETURNS_AND_REFUNDS_POLICY.TITLE',
    content: [
      {
        prelinkText: `SHIPPING_INFO.EGY.RETURNS_AND_REFUNDS_POLICY.PRE_LINK_TEXT`,
        link: {
          text: 'SHIPPING_INFO.EGY.RETURNS_AND_REFUNDS_POLICY.LINK_TEXT',
          src: 'https://www.jawdaonline.com/egy-request',
        },
        postlinkText: 'SHIPPING_INFO.EGY.RETURNS_AND_REFUNDS_POLICY.POST_LINK_TEXT',
        notes: [
          'SHIPPING_INFO.EGY.RETURNS_AND_REFUNDS_POLICY.NOTE_1',
          'SHIPPING_INFO.EGY.RETURNS_AND_REFUNDS_POLICY.NOTE_2',
          'SHIPPING_INFO.EGY.RETURNS_AND_REFUNDS_POLICY.NOTE_3',
        ],
      },
    ],
  },
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  SAU: {
    title: 'SHIPPING_INFO.SAU.RETURNS_AND_REFUNDS_POLICY.TITLE',
    content: [
      {
        text: 'SHIPPING_INFO.SAU.RETURNS_AND_REFUNDS_POLICY.TEXT',
      },
    ],
  },
  ARE: {
    title: 'SHIPPING_INFO.SAU.RETURNS_AND_REFUNDS_POLICY.TITLE',
    content: [
      {
        text: 'SHIPPING_INFO.SAU.RETURNS_AND_REFUNDS_POLICY.TEXT',
      },
    ],
  },
  IRQ: {
    title: 'SHIPPING_INFO.SAU.RETURNS_AND_REFUNDS_POLICY.TITLE',
    content: [
      {
        text: 'SHIPPING_INFO.IRQ.RETURNS_AND_REFUNDS_POLICY.TEXT',
      },
    ],
  },
};

export const OTHER_INFO_SECTIONS: any = {
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  EGY: [
    {
      title: 'SHIPPING_INFO.EGY.PROFITS_WITHDRAWAL.TITLE',
      content: [
        {
          text: `SHIPPING_INFO.EGY.PROFITS_WITHDRAWAL.TEXT`,
        },
      ],
    },
    {
      title: 'SHIPPING_INFO.EGY.INCREASE_DR_ADVICE.TITLE',
      content: [
        {
          text: `SHIPPING_INFO.EGY.INCREASE_DR_ADVICE.TEXT_1`,
        },
        {
          text: `SHIPPING_INFO.EGY.INCREASE_DR_ADVICE.TEXT_2`,
        },
        {
          text: `SHIPPING_INFO.EGY.INCREASE_DR_ADVICE.TEXT_3`,
        },
        {
          text: 'SHIPPING_INFO.EGY.INCREASE_DR_ADVICE.TEXT_4',
        },
        {
          text: `SHIPPING_INFO.EGY.INCREASE_DR_ADVICE.TEXT_5`,
        },
        {
          text: 'SHIPPING_INFO.EGY.INCREASE_DR_ADVICE.TEXT_6',
        },
        {
          text: 'SHIPPING_INFO.EGY.INCREASE_DR_ADVICE.TEXT_7',
        },
        {
          text: `SHIPPING_INFO.EGY.INCREASE_DR_ADVICE.TEXT_8`,
        },
      ],
    },
  ],
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  SAU: [
    {
      title: 'SHIPPING_INFO.SAU.VAT.TITLE',
      content: [
        {
          text: 'SHIPPING_INFO.SAU.VAT.TEXT',
        },
      ],
    },
    {
      title: 'SHIPPING_INFO.SAU.LIVE_SUPPORT.TITLE',
      content: [
        {
          image: 'live-chat-support.jpeg',
        },
      ],
    },
    {
      title: 'SHIPPING_INFO.SAU.SAUDI_MARKET_GUIDE.TITLE',
      content: [
        {
          videoLink: 'https://www.youtube.com/embed/Q7fUj8SnnFQ',
        },
      ],
    },
  ],
  ARE: [],
  IRQ: [
    {
      title: 'SHIPPING_INFO.IRQ.CASH_AND_PROFIT_WITHDRAWAL_REQUESTS.TITLE',
      content: [
        {
          text: 'SHIPPING_INFO.IRQ.CASH_AND_PROFIT_WITHDRAWAL_REQUESTS.TEXT',
        },
      ],
    },
    {
      title: 'SHIPPING_INFO.IRQ.TIPS.TITLE',
      content: [
        {
          text: 'SHIPPING_INFO.IRQ.TIPS.TIP1',
        },
        {
          text: 'SHIPPING_INFO.IRQ.TIPS.TIP2',
        },
        {
          text: 'SHIPPING_INFO.IRQ.TIPS.TIP3',
        },
        {
          text: 'SHIPPING_INFO.IRQ.TIPS.TIP4',
        },
        {
          text: 'SHIPPING_INFO.IRQ.TIPS.TIP5',
        },
        {
          text: 'SHIPPING_INFO.IRQ.TIPS.TIP6',
        },
        {
          text: 'SHIPPING_INFO.IRQ.TIPS.TIP7',
        },
        {
          text: 'SHIPPING_INFO.IRQ.TIPS.TIP8',
        },
      ],
    },
  ],
};
