<div class="container">
  <div class="info" *ngIf="isSelectedCountryCovered; else countryNotCovered">
    <h1>{{ orderStatusesPolicy.title | translate }}</h1>
    <section *ngFor="let snippet of orderStatusesPolicy.content">
      <div class="bar"></div>
      <h2>{{ snippet.subtitle | translate }}</h2>
      <h3 class="info-text">{{ snippet.text | translate }}</h3>
    </section>
    <section>
      <h1>{{ shippingCostPolicy.title | translate }}</h1>
      <img
        loading="lazy"
        *ngIf="shippingCostPolicy.image"
        src="../../../assets/img/{{ shippingCostPolicy.image }}"
        [alt]="shippingCostPolicy.image"
        class="shipping-cost-table-image"
      />
      <ng-container *ngIf="shippingCostPolicy.text">
        <div class="bar"></div>
        <h3>{{ shippingCostPolicy.text | translate }}</h3>
      </ng-container>
    </section>
    <h1>{{ refundsAndReturnsPolicy.title | translate }}</h1>
    <section *ngFor="let snippet of refundsAndReturnsPolicy.content">
      <div class="bar"></div>
      <h3>
        {{ snippet.text || snippet.prelinkText | translate }}
        <a [href]="snippet.link.src" *ngIf="snippet?.link">{{ snippet.link.text | translate }}</a>
        {{ snippet.postlinkText || '' | translate }}
        <ng-container *ngIf="snippet.notes">
          <br />
          {{ 'SHIPPING_INFO.NOTE' | translate }}:
          <br />
          <ng-container *ngFor="let note of snippet.notes">
            {{ note | translate }}<br />
          </ng-container>
        </ng-container>
      </h3>
    </section>
    <section *ngFor="let section of otherSections">
      <h1>{{ section.title | translate }}</h1>
      <ng-container *ngFor="let snippet of section.content">
        <ng-container *ngIf="snippet.text">
          <div class="bar"></div>
          <h3>{{ snippet.text | translate }}</h3>
        </ng-container>
        <div class="video-frame" *ngIf="snippet.videoLink">
          <iframe
            width="560"
            height="315"
            [src]="snippet.videoLink | safe"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
        <img
          loading="lazy"
          *ngIf="snippet.image"
          src="../../../assets/img/{{ snippet.image }}"
          [alt]="snippet.image"
          class="live-chat-table-image"
        />
      </ng-container>
    </section>
  </div>

  <ng-template #countryNotCovered>
    <div class="no-shipping-info">
      <img src="assets/img/empty-shipping-info.svg" alt="Empty stores page" />
      <p class="heading2--bold">{{ trans('SHIPPING_INFO.NO_SHIPPING_INFO_TITLE') }}</p>
      <p class="body2--regular">{{ trans('SHIPPING_INFO.NO_SHIPPING_INFO_BODY') }}</p>
    </div>
  </ng-template>
</div>
