import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { country } from '@features/country/data';
import { TranslateModule } from '@ngx-translate/core';
import {
  ORDER_STATUSES_POLICY,
  OTHER_INFO_SECTIONS,
  REFUNDS_AND_RETURNS_POLICY,
  SHIPPING_COST_POLICY,
} from '../../constants/shipping-info';

import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { SafePipe } from '../../pipes/safe.pipe';
import { ResponsiveService } from '../../services/responsive.service';

@Component({
  selector: 'app-shipping-info',
  templateUrl: './shipping-info.component.html',
  styleUrls: ['./shipping-info.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, TranslateModule, SafePipe],
})
export class ShippingInfoComponent extends LocalizedComponent implements OnInit {
  public isMobile: boolean;

  public selectedCountry: string;

  public isSelectedCountryCovered = false;

  public orderStatusesPolicy: {
    title: string;
    content: { subtitle: string; text: string | string[] }[];
  };

  public shippingCostPolicy: { title: string; text?: string; image?: string };

  public refundsAndReturnsPolicy: { title: string; content: { text: string; note?: string }[] };

  public otherSections: {
    title: string;
    content: { text?: string; videoLink?: string; image?: string }[];
  }[];

  constructor(private responsiveService: ResponsiveService) {
    super();
  }

  ngOnInit() {
    this.responsiveService.getMobileStatus().subscribe({
      next: (isMobile) => {
        this.isMobile = isMobile;
      },
    });
    this.selectedCountry = country.code;

    this.orderStatusesPolicy = ORDER_STATUSES_POLICY[this.selectedCountry];
    this.shippingCostPolicy = SHIPPING_COST_POLICY[this.selectedCountry];
    this.refundsAndReturnsPolicy = REFUNDS_AND_RETURNS_POLICY[this.selectedCountry];
    this.otherSections = OTHER_INFO_SECTIONS[this.selectedCountry];
    this.isSelectedCountryCovered = !!(
      this.orderStatusesPolicy ||
      this.shippingCostPolicy ||
      this.refundsAndReturnsPolicy ||
      this.otherSections
    );
  }
}
